import * as i0 from '@angular/core';
import { Injectable, EventEmitter, Component, ViewChild, Input, Output, NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import html2canvas from 'html2canvas';
import { of, from, Subject } from 'rxjs';
import { take, tap } from 'rxjs/operators';
const _c0 = ["rect"];
const _c1 = ["over"];
const _c2 = ["*"];
class NgxCaptureService {
  getImage(screen, fullCapture, cropDimensions) {
    let options = {
      logging: false,
      useCORS: true,
      scale: 1
    };
    if (!fullCapture && cropDimensions.width > 10 && cropDimensions.height > 10) {
      options = {
        ...options,
        ...cropDimensions
      };
    } else if (!fullCapture) {
      return of(null);
    }
    return from(html2canvas(screen, options).then(canv => {
      return canv.toDataURL('image/png');
    }, err => {
      throw new Error(err);
    }).catch(res => {
      throw new Error(res);
    }));
  }
  downloadImage(img) {
    const element = document.createElement('a');
    element.setAttribute('href', img);
    element.setAttribute('download', 'capture');
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }
}
NgxCaptureService.ɵfac = function NgxCaptureService_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || NgxCaptureService)();
};
NgxCaptureService.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: NgxCaptureService,
  factory: NgxCaptureService.ɵfac,
  providedIn: 'root'
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgxCaptureService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], null, null);
})();
class NgxCaptureComponent {
  constructor(captureService) {
    this.captureService = captureService;
    this.resultImage = new EventEmitter();
    this.isDrawing = false;
    this.mouseStart = {
      x: 0,
      y: 0
    };
    this.cropDimensions = {
      x: 0,
      y: 0,
      width: 0,
      height: 0
    };
    this.destroy$ = new Subject();
  }
  ngOnInit() {
    setTimeout(() => {
      this.rect = this.rectangle.nativeElement;
      this.captureZone = this.overlay.nativeElement;
      if (!this.captureZone) {
        console.warn('"captureZone" is not set');
        return;
      }
      this.captureZone.onmousedown = e => this.startCapture(e);
      this.captureZone.onmousemove = e => this.drawRect(e);
      this.captureZone.onmouseup = () => this.endCapture();
    }, 2000);
  }
  startCapture(e) {
    const mouse = this.setMousePosition(e, true);
    this.isDrawing = true;
    this.cropDimensions = {
      x: mouse.x,
      y: mouse.y,
      width: 0,
      height: 0
    };
    this.captureZone.style.cursor = 'crosshair';
  }
  drawRect(e) {
    if (this.isDrawing) {
      const mouse = this.setMousePosition(e, false);
      this.cropDimensions = {
        x: mouse.x - this.mouseStart.x < 0 ? mouse.x : this.mouseStart.x,
        y: mouse.y - this.mouseStart.y < 0 ? mouse.y : this.mouseStart.y,
        width: Math.abs(mouse.x - this.mouseStart.x),
        height: Math.abs(mouse.y - this.mouseStart.y)
      };
      this.setRectangle();
    }
  }
  setMousePosition(e, isStart = false) {
    const ev = e || window.event; // Moz || IE
    const mouse = {
      x: 0,
      y: 0
    };
    if (ev.pageX) {
      // Moz
      mouse.x = ev.clientX;
      mouse.y = ev.clientY;
    } else if (ev.clientX) {
      // IE
      mouse.x = ev.clientX + document.body.scrollLeft;
      mouse.y = ev.clientY + document.body.scrollTop;
    }
    if (isStart) {
      this.mouseStart.x = mouse.x;
      this.mouseStart.y = mouse.y;
    }
    return mouse;
  }
  endCapture() {
    this.captureZone.style.cursor = 'default';
    this.isDrawing = false;
    this.captureService.getImage(this.target, false, {
      ...this.cropDimensions,
      x: this.cropDimensions.x + window.scrollX,
      y: this.cropDimensions.y + window.scrollY
    }).pipe(take(1), tap(img => {
      this.resultImage.emit(img);
    })).subscribe();
    this.cropDimensions = {
      x: 0,
      y: 0,
      width: 0,
      height: 0
    };
    this.setRectangle();
  }
  setRectangle() {
    this.rect.style.left = this.cropDimensions.x + 'px';
    this.rect.style.top = this.cropDimensions.y + 'px';
    this.rect.style.width = this.cropDimensions.width + 'px';
    this.rect.style.height = this.cropDimensions.height + 'px';
  }
}
NgxCaptureComponent.ɵfac = function NgxCaptureComponent_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || NgxCaptureComponent)(i0.ɵɵdirectiveInject(NgxCaptureService));
};
NgxCaptureComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: NgxCaptureComponent,
  selectors: [["ngx-capture"]],
  viewQuery: function NgxCaptureComponent_Query(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵviewQuery(_c0, 7);
      i0.ɵɵviewQuery(_c1, 7);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.rectangle = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.overlay = _t.first);
    }
  },
  inputs: {
    target: "target"
  },
  outputs: {
    resultImage: "resultImage"
  },
  standalone: false,
  ngContentSelectors: _c2,
  decls: 5,
  vars: 0,
  consts: [["over", ""], ["rect", ""], [1, "overlay"], [1, "rectangle"]],
  template: function NgxCaptureComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
      i0.ɵɵelementStart(1, "div", 2, 0);
      i0.ɵɵelement(3, "div", 3, 1);
      i0.ɵɵelementEnd();
    }
  },
  styles: [".overlay[_ngcontent-%COMP%]{top:0;left:0;position:fixed;width:100vw;height:100vh}.rectangle[_ngcontent-%COMP%]{border:1px solid #ff0000;position:absolute}"]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgxCaptureComponent, [{
    type: Component,
    args: [{
      selector: 'ngx-capture',
      template: `
    <ng-content></ng-content>
    <div class="overlay" #over>
      <div class="rectangle" #rect></div>
    </div>
  `,
      styles: [".overlay{top:0;left:0;position:fixed;width:100vw;height:100vh}.rectangle{border:1px solid #ff0000;position:absolute}\n"]
    }]
  }], function () {
    return [{
      type: NgxCaptureService
    }];
  }, {
    rectangle: [{
      type: ViewChild,
      args: ['rect', {
        static: true
      }]
    }],
    overlay: [{
      type: ViewChild,
      args: ['over', {
        static: true
      }]
    }],
    target: [{
      type: Input
    }],
    resultImage: [{
      type: Output
    }]
  });
})();
class NgxCaptureModule {}
NgxCaptureModule.ɵfac = function NgxCaptureModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || NgxCaptureModule)();
};
NgxCaptureModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: NgxCaptureModule
});
NgxCaptureModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgxCaptureModule, [{
    type: NgModule,
    args: [{
      declarations: [NgxCaptureComponent],
      imports: [],
      exports: [NgxCaptureComponent],
      schemas: [CUSTOM_ELEMENTS_SCHEMA]
    }]
  }], null, null);
})();

/*
 * Public API Surface of ngx-capture
 */

/**
 * Generated bundle index. Do not edit.
 */

export { NgxCaptureComponent, NgxCaptureModule, NgxCaptureService };
